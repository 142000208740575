import { i18n } from '@whoop/i18n/lang/client';
import {
  ProductType,
  type WhoopYourWayConfigurations,
  type WhoopYourWayOption,
  type Media,
  type WhoopYourWayBase,
  type WhoopYourWayProduct,
  type WhoopYourWaySelection,
} from '../types';
import { WYW_SKU } from './fixtures/helperFixtures';
import {
  getAllNonSwatchImages,
  getCartItemDisplayPhoto,
  getWYWBackPhoto,
  SelectedVariantMap,
} from './variantHelpers';
import { Product, Variant } from 'services/generated/commerce-service';
import { getFirstVariant } from './productHelpers';

export type WYWImages = {
  front: string[];
  back: string[];
};

export const buildWywCompositeSku = (
  selection: WhoopYourWaySelection,
): string => {
  const BBB = selection.band.code;
  const HHH = selection.hook.code;
  const CCC = selection.clasp.code;
  const SSS = selection.slider.code;
  return `956-${BBB}-${HHH}-${CCC}-${SSS}`;
};

export const buildWywParentSku = (product: WhoopYourWayProduct): string => {
  return `wyw-${product.display_price / 100}`;
};

export const getTotalSelectionPrice = (
  selection: WhoopYourWaySelection,
): number => {
  const bandPrice = selection.band.price;
  const claspPrice = selection.clasp.price;
  const hookPrice = selection.hook.price;
  const sliderPrice = selection.slider.price;
  return bandPrice + hookPrice + sliderPrice + claspPrice;
};

export const urlFromMedia = (media: Media): string => {
  return (
    media.url_path + (media.url_path.endsWith('/') ? '' : '/') + media.filename
  );
};

export const getImagesFromSelection = (
  base: WhoopYourWayBase,
  selection: WhoopYourWaySelection,
): { frontImages: string[]; backImages: string[] } => {
  return {
    frontImages: [
      urlFromMedia(base.front_media),
      urlFromMedia(selection.band.front_media),
      urlFromMedia(selection.hook.front_media),
      urlFromMedia(selection.slider.front_media),
      urlFromMedia(selection.clasp.front_media),
    ],
    backImages: [
      urlFromMedia(base.back_media),
      urlFromMedia(selection.band.back_media),
      urlFromMedia(selection.slider.back_media),
      urlFromMedia(selection.clasp.back_media),
    ],
  };
};
export const BAND_KEY = 'wyw-band';
export const CLASP_KEY = 'wyw-clasp';
export const HOOK_KEY = 'wyw-hook';
export const FAST_LINK_KEY = 'wyw-fast-link';
export const WYW_CATEGORIES_ORDER = [
  BAND_KEY,
  CLASP_KEY,
  HOOK_KEY,
  FAST_LINK_KEY,
];
export const WYW_IMAGES_ORDER = [
  WYW_SKU,
  BAND_KEY,
  HOOK_KEY,
  FAST_LINK_KEY,
  CLASP_KEY,
];

export const getDescriptionsFromSelection = (
  selection: WhoopYourWaySelection,
): string[] => {
  return [
    `${i18n.t('whoopYourWay:band')}: ${selection.band.color_text}`,
    `${i18n.t('whoopYourWay:clasp')}: ${selection.clasp.color_text}`,
    `${i18n.t('whoopYourWay:hook')}: ${selection.hook.color_text}`,
    `${i18n.t('whoopYourWay:fastLink')}: ${selection.slider.color_text}`,
  ];
};

export const buildProductFromSelection = (
  base: WhoopYourWayBase,
  selection: WhoopYourWaySelection,
): WhoopYourWayProduct => {
  const price = getTotalSelectionPrice(selection);
  return {
    id: buildWywCompositeSku(selection),
    accessory_type: 'whoop_your_way',
    product_type: ProductType.ACCESSORY,
    display_price: price,
    subtotal: price,
    inventory_information: { min_quantity: 0, max_quantity: 10 },
    cart_information: {
      images: getImagesFromSelection(base, selection).frontImages,
      name: i18n.t('whoopYourWay:whoopYourWay'),
      descriptions: getDescriptionsFromSelection(selection),
    },
  };
};

const findSelectionFromCode = (
  code: string,
  options: WhoopYourWayOption[],
): WhoopYourWayOption | undefined => {
  return options.find((option) => option.code === code);
};

export const buildWywProductFromSku = (
  sku: string,
  wywConfig: WhoopYourWayConfigurations,
): WhoopYourWayProduct | undefined => {
  // check that SKU is in the format of a WYW sku
  // These skus should be in the format of "956-[band code]-[hook code]-[clasp code]-[slider code]"

  const selections = sku.split('-');
  if (selections.length !== 5 || selections[0] !== '956') {
    return;
  }
  const bandSelection: WhoopYourWayOption | undefined = findSelectionFromCode(
    selections[1],
    wywConfig.band.options,
  );
  const hookSelection: WhoopYourWayOption | undefined = findSelectionFromCode(
    selections[2],
    wywConfig.hook.options,
  );
  const claspSelection: WhoopYourWayOption | undefined = findSelectionFromCode(
    selections[3],
    wywConfig.clasp.options,
  );
  const sliderSelection: WhoopYourWayOption | undefined = findSelectionFromCode(
    selections[4],
    wywConfig.slider.options,
  );

  if (!bandSelection || !hookSelection || !claspSelection || !sliderSelection) {
    return;
  }

  // now that we know the SKU is a WYW sku, construct the WhoopYourWayProduct
  const selection: WhoopYourWaySelection = {
    band: bandSelection,
    hook: hookSelection,
    clasp: claspSelection,
    slider: sliderSelection,
  };
  const price = getTotalSelectionPrice(selection);

  return {
    id: sku,
    accessory_type: 'whoop_your_way',
    product_type: ProductType.ACCESSORY,
    display_price: price,
    subtotal: price,
    inventory_information: { min_quantity: 0, max_quantity: 10 },
    cart_information: {
      images: getImagesFromSelection(wywConfig.base, selection).frontImages,
      name: i18n.t('whoopYourWay:whoopYourWay'),
      descriptions: getDescriptionsFromSelection(selection),
    },
  };
};

// Appending -large (or another size) to the image url fetches a compressed
// version of the image from commerce tools. For now, we only need to use this for WYW
export const getCustomImageSize = (imageUrl: string) => {
  const list = imageUrl.split('.png');
  return list[0] + '-large.png';
};

// Get the base, band, clasp, hook, and link photos in 1 array so they
// <LayeredImage /> component can stack the photos
export const createWYWImagesObject = (
  wywVariant: Variant,
  selectedWywOptions: SelectedVariantMap,
): WYWImages => {
  let wywFrontImages: string[] = [];
  let wywBackImages: string[] = [];

  const baseFrontImage = getCartItemDisplayPhoto(wywVariant);
  const baseBackImage = getWYWBackPhoto(wywVariant);

  if (baseFrontImage) wywFrontImages.push(baseFrontImage);
  if (baseBackImage) wywBackImages.push(baseBackImage);

  Object.keys(selectedWywOptions).forEach((key) => {
    const frontImage = getCartItemDisplayPhoto(selectedWywOptions[key]);
    const backImage = getWYWBackPhoto(selectedWywOptions[key]);

    if (frontImage) wywFrontImages.push(getCustomImageSize(frontImage));
    if (backImage) wywBackImages.push(getCustomImageSize(backImage));
  });

  return { front: wywFrontImages, back: wywBackImages };
};

export const getAllWYWImages = (products: Product[]): string[] => {
  return products.reduce((stringArray: string[], product) => {
    const images = getAllNonSwatchImages(getFirstVariant(product)).map((img) =>
      getCustomImageSize(img),
    );
    stringArray.push(...images);
    return stringArray;
  }, []);
};

import type {
  AccessoryGroup,
  JoinFlowContent,
} from 'services/generated/growth-content-service';
import type { Membership } from 'services/generated/membership-service-v2';
import type {
  Affiliate,
  CartProduct,
  DeviceProduct,
  MembershipProduct,
  PromoCode,
  PromoInfo,
  Referrer,
  WhoopYourWayProduct,
  AccessoryProduct,
} from '../../types';
import { ProductType } from '../../types';

// PROMOTION Fixtures
export const TRIAL_MEMBERSHIP_SKU = '940-000003-120';
export const MONTHLY_MEMBERSHIP_SKU = '940-000003-121';

export const REFERRAL_CODE = 'REFERRAL';
export const REFERRAL_PROMO_CODE: PromoCode = {
  created_at: '',
  updated_at: '',
  code: REFERRAL_CODE,
  application_schemes: [
    {
      promo_code: REFERRAL_CODE,
      promo_type: 'month_off',
      amount: 1,
      application_type: 'membership',
      currency: undefined,
    },
  ],
  rules: [
    {
      promo_code: REFERRAL_CODE,
      rule_type: 'EXCLUDED',
      sku: TRIAL_MEMBERSHIP_SKU,
      item_type: 'membership',
      max_quantity: undefined,
    },
  ],
  active: true,
};
export const REFERRER: Referrer = {
  first_name: 'Referrer Firstname',
  avatar_url: '/image.png',
};
export const REFERRAL_PROMO_INFO: PromoInfo = {
  promo_code: REFERRAL_PROMO_CODE,
  referral: {
    referrer: REFERRER,
    code: {
      code: REFERRAL_CODE,
      user_id: 1,
      source: 'trials-onboarding',
    },
  },
};
export const AFFILIATE_CODE = 'AFFILIATE';
export const AFFILIATE_PROMO_CODE: PromoCode = {
  created_at: '',
  updated_at: '',
  code: AFFILIATE_CODE,
  application_schemes: [
    {
      promo_code: AFFILIATE_CODE,
      promo_type: 'month_off',
      amount: 1,
      application_type: 'membership',
      currency: undefined,
    },
  ],
  rules: [
    {
      promo_code: AFFILIATE_CODE,
      rule_type: 'EXCLUDED',
      sku: TRIAL_MEMBERSHIP_SKU,
      item_type: 'membership',
      max_quantity: undefined,
    },
  ],
  active: true,
};
export const AFFILIATE: Affiliate = {
  id: 1,
  affiliate_code: AFFILIATE_CODE,
  name: 'Affiliate User',
  avatar_url: '/image.png',
};
export const AFFILIATE_PROMO_INFO: PromoInfo = {
  promo_code: AFFILIATE_PROMO_CODE,
  affiliate: AFFILIATE,
};

export const REFERRAL_PROMO_CODE_AMOUNT_OFF: PromoCode = {
  created_at: '',
  updated_at: '',
  code: REFERRAL_CODE,
  application_schemes: [
    {
      promo_code: REFERRAL_CODE,
      promo_type: 'amount_off',
      amount: 1000,
      currency: undefined,
    },
  ],
  rules: [
    {
      promo_code: REFERRAL_CODE,
      rule_type: 'EXCLUDED',
      sku: TRIAL_MEMBERSHIP_SKU,
      item_type: 'membership',
      max_quantity: undefined,
    },
  ],
  active: true,
};

export const REFERRAL_PROMO_CODE_AMOUNT_OFF_INFO: PromoInfo = {
  promo_code: REFERRAL_PROMO_CODE_AMOUNT_OFF,
  referral: {
    referrer: {
      first_name: 'Firstname',
    },
    code: {
      code: REFERRAL_CODE,
      user_id: 1,
      source: 'trials-onboarding',
    },
  },
};

export const PARTNERSHIP_CODE = 'PARTNER';
export const PARTNERSHIP_PROMO_CONTENT: JoinFlowContent = {
  content: {
    base_desktop_subtext: 'Save $50',
    base_mobile_subtext: 'Save $50',
    membership_subtext: '$50 off',
    image: 'https://image.com',
  },
  promo_info: {
    code: PARTNERSHIP_CODE,
    application_schemes: [
      {
        promo_type: 'amount_off',
        amount: 5000,
        application_type: 'membership',
        currency: 'usd',
      },
    ],
    rules: [],
  },
  partnership_name: PARTNERSHIP_CODE,
};

export const WHOLE_ORDER_AMOUNT_OFF_PROMO_CODE: PromoCode = {
  created_at: '',
  updated_at: '',
  code: 'PROMO',
  application_schemes: [
    {
      promo_code: 'PROMO',
      promo_type: 'amount_off',
      amount: 5000,
      application_type: undefined,
      currency: 'usd',
    },
  ],
  rules: [],
  active: true,
};

export const WHOLE_ORDER_PERCENT_OFF_PROMO_CODE: PromoCode = {
  created_at: '',
  updated_at: '',
  code: 'PROMO',
  application_schemes: [
    {
      promo_code: 'PROMO',
      promo_type: 'percent_off',
      amount: 10,
      application_type: undefined,
      currency: undefined,
    },
  ],
  rules: [],
  active: true,
};

export const INCLUDED_PROMO_CODE: PromoCode = {
  created_at: '',
  updated_at: '',
  code: REFERRAL_CODE,
  application_schemes: [
    {
      promo_code: REFERRAL_CODE,
      promo_type: 'month_off',
      amount: 1,
      application_type: 'membership',
      currency: undefined,
    },
  ],
  rules: [
    {
      promo_code: REFERRAL_CODE,
      rule_type: 'INCLUDED',
      sku: MONTHLY_MEMBERSHIP_SKU,
      item_type: 'membership',
      max_quantity: undefined,
    },
  ],
  active: true,
};

export const SITE_WIDE_CODE = 'CYBERSALE2023';
export const SITE_WIDE_PROMO_INFO = {
  promo_code: {
    ...WHOLE_ORDER_PERCENT_OFF_PROMO_CODE,
    code: SITE_WIDE_CODE,
  },
};

// PRODUCT Fixtures
export const ONYX_SKU = '955-01-000-000008';
export const ANNUAL_MEMBERSHIP_SKU = '940-000003-312';
export const ANNUAL_MEMBERSHIP_PRODUCT: MembershipProduct = {
  id: ANNUAL_MEMBERSHIP_SKU,
  display_price: 23900,
  subtotal: 23900,
  product_type: 'membership',
  cart_information: {
    image: '',
    name: 'WHOOP Membership',
    description: 'Plan: Annual Membership',
  },
  membership_type: 'prepaid',
  title: 'Annual Membership',
  trial_months: 12,
  commitment_months: 0,
  inventory_information: {
    min_quantity: 1,
    max_quantity: 10,
  },
};

export const ANNUAL_MEMBERSHIP_CART_PRODUCT: CartProduct = {
  item: ANNUAL_MEMBERSHIP_PRODUCT,
  quantity: 1,
};

export const ANNUAL_MEMBERSHIP_CART_PRODUCT_TWO: CartProduct = {
  item: ANNUAL_MEMBERSHIP_PRODUCT,
  quantity: 2,
};

export const DEVICE_SKU = '970-001-000';
export const DEVICE_PRODUCT: DeviceProduct = {
  id: DEVICE_SKU,
  display_price: 0,
  subtotal: 0,
  product_type: 'device',
  cart_information: {
    image: '',
    name: 'WHOOP 4.0 Device',
    description: 'Battery, Onyx band, and lifetime warranty included',
  },
  device_type: 'default',
  generation: 4,
};

export const DEVICE_PRODUCT_2: DeviceProduct = {
  id: `${DEVICE_SKU}-2`,
  display_price: 0,
  subtotal: 0,
  product_type: 'device',
  cart_information: {
    image: '',
    name: 'WHOOP 4.0 Device',
    description: 'Battery, Onyx band, and lifetime warranty included',
  },
  device_type: 'engraved',
  generation: 4,
};

export const DEVICE_CART_PRODUCT: CartProduct = {
  item: DEVICE_PRODUCT,
  quantity: 1,
};

export const DEVICE_CART_PRODUCT_TWO: CartProduct = {
  item: DEVICE_PRODUCT,
  quantity: 2,
};

export const TRIAL_MEMBERSHIP_PRODUCT: MembershipProduct = {
  id: TRIAL_MEMBERSHIP_SKU,
  display_price: 0,
  subtotal: 0,
  product_type: 'membership',
  cart_information: {
    image: '',
    name: 'WHOOP Trial Membership',
    description: 'Plan: 1 Month Trial',
  },
  membership_type: 'trial',
  title: '1 Month Free Trial',
  trial_months: 1,
  commitment_months: 12,
};

export const TRIAL_MEMBERSHIP_CART_PRODUCT: CartProduct = {
  item: TRIAL_MEMBERSHIP_PRODUCT,
  quantity: 1,
};

export const MONTHLY_MEMBERSHIP_PRODUCT: MembershipProduct = {
  id: MONTHLY_MEMBERSHIP_SKU,
  display_price: 3000,
  subtotal: 3000,
  product_type: 'membership',
  cart_information: {
    image: '',
    name: 'WHOOP Membership',
    description: 'Plan: Monthly Membership',
  },
  membership_type: 'monthly',
  title: 'Monthly Membership',
  trial_months: 1,
  commitment_months: 12,
};

export const MONTHLY_MEMBERSHIP_CART_PRODUCT: CartProduct = {
  item: MONTHLY_MEMBERSHIP_PRODUCT,
  quantity: 1,
};

export const TWO_YEAR_MEMBERSHIP_SKU = '940-000003-124';
export const TWO_YEAR_MEMBERSHIP_PRODUCT: MembershipProduct = {
  id: TWO_YEAR_MEMBERSHIP_SKU,
  display_price: 39900,
  subtotal: 39900,
  product_type: 'membership',
  cart_information: {
    image: '',
    name: 'WHOOP Membership',
    description: 'Plan: 24 Month Membership',
  },
  membership_type: 'prepaid',
  title: '24 Month Membership',
  trial_months: 24,
  commitment_months: 0,
};
// 2-Person-Family-Membership
export const FAMILY_MEMBERSHIP_SIZE_2_SKU = '2-Person-Family-Membership';
export const FAMILY_MEMBERSHIP_PRODUCT_SIZE_2: MembershipProduct = {
  membership_type: 'family',
  family_size: 2,
  title: 'Family',
  trial_months: 12,
  commitment_months: 0,
  tag: 'BEST VALUE',
  id: FAMILY_MEMBERSHIP_SIZE_2_SKU,
  display_price: 43000,
  subtotal: 43000,
  tax: null,
  product_type: 'membership',
  cart_information: {
    image: 'https://join.whoop.com/assets/whoop-circle-logo@2x.png',
    name: 'WHOOP Membership',
    description: 'Family Membership',
  },
};

export const FAMILY_MEMBERSHIP_SIZE_3_SKU = '3-Person-Family-Membership';
export const FAMILY_MEMBERSHIP_PRODUCT_SIZE_3: MembershipProduct = {
  membership_type: 'family',
  family_size: 3,
  title: 'Family',
  trial_months: 12,
  commitment_months: 0,
  tag: 'BEST VALUE',
  id: FAMILY_MEMBERSHIP_SIZE_3_SKU,
  display_price: 62700,
  subtotal: 62700,
  tax: null,
  product_type: 'membership',
  cart_information: {
    image: 'https://join.whoop.com/assets/whoop-circle-logo@2x.png',
    name: 'WHOOP Membership',
    description: 'Family Membership',
  },
};

export const JOIN_MEMBERSHIPS = [
  ANNUAL_MEMBERSHIP_PRODUCT,
  TWO_YEAR_MEMBERSHIP_PRODUCT,
  TRIAL_MEMBERSHIP_PRODUCT,
  MONTHLY_MEMBERSHIP_PRODUCT,
  FAMILY_MEMBERSHIP_PRODUCT_SIZE_2,
  FAMILY_MEMBERSHIP_PRODUCT_SIZE_3,
];

export const WYW_SKU = 'wyw-sku';

export const WHOOP_YOUR_WAY_PRODUCT: WhoopYourWayProduct = {
  id: WYW_SKU,
  accessory_type: 'whoop_your_way',
  product_type: ProductType.ACCESSORY,
  display_price: 15000,
  subtotal: 15000,
  inventory_information: {
    max_quantity: 4,
    min_quantity: 1,
  },
  cart_information: {
    images: [],
    name: 'whoop_your_way_product',
    descriptions: [],
  },
};

export const EXTRA_BATTERY_ACCESSORY_PRODUCT: CartProduct = {
  item: {
    id: '0',
    accessory_type: 'hardware',
    product_type: ProductType.ACCESSORY,
    display_price: 4900,
    subtotal: 4900,
    inventory_information: {
      max_quantity: 10,
      min_quantity: 1,
    },
    cart_information: {
      image: '',
      name: '',
      description: '',
    },
    variant_information: {},
  },
  quantity: 1,
};

export const EXTRA_BATTERY_ACCESSORY_PRODUCT_TWO: CartProduct = {
  ...EXTRA_BATTERY_ACCESSORY_PRODUCT,
  quantity: 2,
};

export const ENGRAVING_ACCESSORY_PRODUCT: AccessoryProduct = {
  id: '0',
  accessory_type: 'engraving',
  product_type: ProductType.ACCESSORY,
  display_price: 15000,
  subtotal: 15000,
  inventory_information: {
    max_quantity: 4,
    min_quantity: 1,
  },
  cart_information: {
    image: '',
    name: 'engraving_product',
    description: '',
  },
  variant_information: {},
};

export const ENGRAVING_ACCESSORY_CART_PRODUCT: CartProduct = {
  item: ENGRAVING_ACCESSORY_PRODUCT,
  quantity: 1,
};

export const ENGRAVING_ACCESSORY_GROUP: AccessoryGroup = {
  handle: 'engraving',
  group_type: 'engraving',
  products: [ENGRAVING_ACCESSORY_PRODUCT],
  colors: [
    {
      handle: 'color',
      label: 'color',
    },
  ],
  sizes: [
    {
      handle: 'color',
      label: 'color',
    },
  ],
  inseams: [
    {
      handle: 'color',
      label: 'color',
    },
  ],
  pack_item_handles: [],
};

export const MEMBERSHIP: Membership = {
  account_id: 1000000,
  email: 'johndoe@whoop.com',
  status: 'active',
  checkout_origin: 'domestic',
  customer_token: 'cus_KrtpDFgiamKE7w',
  card_id: 'card_1Obq7LHLc4GztXOmTRiNpEom',
  card_brand: 'Visa',
  card_last4: '4242',
  card_exp_month: 10,
  card_exp_year: 2027,
  first_name: 'John',
  upgraded_to: '4.0',
  promotional_identifier: 'referral',
  user_id: 1682413,
  next_billing_date: '1921694331000',
  trial_days: 180,
  trial_start: '1640814594000',
  has_annual_plan: true,
  has_whoop_pro: false,
  has_discount: false,
  has_scheduled_cancellation: false,
  created_at: '1640814594000',
  commitment_end: '1921694331000',
  plan_type: '940-000003-AN32',
  is_on_trial_plan: false,
};

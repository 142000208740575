export const JF_EXPRESS_CHECKOUT = 'jf-express-checkout-v5';
export const ADDRESS_VERIFICATION = 'address-verification-v2';

export const MEMBERSHIP_OPTIONS_EXPERIMENT = 'jf-membership-options';

export const DIRECT_TO_CHECKOUT_TRIALS = 'direct-to-checkout-trials';

export const DIRECT_TO_CHECKOUT_PAID = 'direct-to-checkout-paid';
export const JF_SHIPPING_OPTIONS = 'jf-shipping-options';

export const SKIP_MEM_SELECT = 'jf-skip-mem-select';
export type SkipMemVariantOptions =
  | 'control'
  | 'skip-mem-select'
  | 'new-design-skip-mem';

export const JFNP_TRIAL = 'jfnp-trial';

export type JFNPVariants = 'control' | 'experiment';

export const JF_BNPL_V2 = 'experiment-jf-bnpl-v2';

export type JFBNPLV2Variants = 'control' | 'bnpl-mtm' | 'bnpl-no-mtm';

export const JF_MOBILE_ACCESSORY_GRID_EXPERIMENT = 'jf-mobile-accessory-grid';

export const JF_PE_PAYMENT_METHODS = 'jf-pe-payment-methods-v1';

export const JFRP_EX_NZ_AU = 'jfrp-ex-nz-au-v2';
export const JFRP_EX_GCC = 'jfrp-ex-gcc-v1';
export const JFRP_EX_UK_EU = 'jfrp-ex-uk-eu-v1';
export const JFRP_EX_US_CA = 'jfrp-ex-us-ca-v1';
export const JFRP_EX_ROW = 'jfrp-ex-row-v1';
export const JF_REPLATFORM_ROLLOUT_UUID_AU_NZ =
  'jf-replatform-rollout-uuid-au-nz';
export const JF_REPLATFORM_ROLLOUT_UUID_GCC = 'jf-replatform-rollout-uuid-gcc';
export const JF_REPLATFORM_ROLLOUT_UUID_UK_EU =
  'jf-replatform-rollout-uuid-uk-eu';
export const JF_REPLATFORM_ROLLOUT_UUID_US_CA =
  'jf-replatform-rollout-uuid-us-ca';
export const JF_REPLATFORM_ROLLOUT_UUID_ROW = 'jf-replatform-rollout-uuid-row';
export const JF_REPLATFORM_ROLLOUT_AU_NZ = 'jf-replatform-rollout-au-nz';
export const JF_REPLATFORM_ROLLOUT_GCC = 'jf-replatform-rollout-gcc';
export const JF_REPLATFORM_ROLLOUT_UK_EU = 'jf-replatform-rollout-uk-eu';
export const JF_REPLATFORM_ROLLOUT_US_CA = 'jf-replatform-rollout-us-ca';
export const JF_REPLATFORM_ROLLOUT_ROW = 'jf-replatform-rollout-row';

export const NO_EXPERIMENT_REGIONS = ['AE', 'AU', 'NZ', 'SA'];
